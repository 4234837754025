import '/src/css/app.scss';

import Swiper from 'swiper';

import Player from '@vimeo/player';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  EffectFade,
} from 'swiper/modules';
import flatpickr from 'flatpickr';
import mapboxgl from 'mapbox-gl';

import AOS from 'aos';
AOS.init({
  duration: 600,
  once: true,
  mirror: false,
  offset: 150,
});

const scrollIntoViewObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !entry.target.classList.contains('in-view')) {
        entry.target.classList.add('in-view');
      }
    });
  },
  {
    threshold: 1,
    rootMargin: '0px 0px -100px 0px',
  },
);
document
  .querySelectorAll('.link-underline, .lined-list li, .type-prose hr')
  ?.forEach((list) => {
    scrollIntoViewObserver.observe(list);
  });

window.Alpine = Alpine;

Alpine.plugin(collapse);

Alpine.data('accordion', () => ({
  expandedSections: [], // Change from single value to an array
  toggle(index, element) {
    if (this.expandedSections.includes(index)) {
      // If it's already open, remove it (collapse)
      this.expandedSections = this.expandedSections.filter((i) => i !== index);
    } else {
      // If it's closed, add it (expand)
      this.expandedSections.push(index);
    }
  },

  isExpanded(index) {
    return this.expandedSections.includes(index);
  },
}));

Alpine.data('header', ({ hideLogo }) => ({
  megamenuOpen: false,
  hideLogo,
  displayLogo: false,
  pullQuote: '',

  init() {
    if (!hideLogo) return;
    // Show hidden logo on desktop when scrolling.
    window.addEventListener('scroll', this.handleScroll.bind(this), {
      passive: true,
    });
    return () => {
      window.removeEventListener('scroll', this.handleScroll.bind(this));
    };
  },
  handleScroll() {
    this.displayLogo = window.scrollY > 500;
  },
  close() {
    this.megamenuOpen = false;
    document.body.style.overflow = 'auto';
  },
  toggle() {
    this.megamenuOpen = !this.megamenuOpen;
    document.body.style.overflow = !!this.megamenuOpen ? 'hidden' : 'auto';
  },
}));

Alpine.data('slideshow', () => {
  const isReduced =
    window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
    window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
  let swiper = null;

  return {
    play: !isReduced,

    init() {
      if (!this.$refs.carousel) return;
      swiper = new Swiper(this.$refs.carousel, {
        modules: [Autoplay],
        loop: true,
        spaceBetween: 32,
        slidesPerView: 'auto',
        autoplay: this.play
          ? {
              delay: 3000,
              pauseOnMouseEnter: false,
              disableOnInteraction: false,
            }
          : false,
      });

      this.$watch('play', (args) => {
        console.log('PLAY CHANGED', this.play, args);
      });
    },

    playCarousel() {
      if (!swiper) return;
      this.play = true;
      swiper.autoplay.start();
    },
    pauseCarousel() {
      if (!swiper) return;
      this.play = false;
      swiper.autoplay.stop();
    },
  };
});

Alpine.data('mapSlideshow', () => {
  let swiper = null;

  return {
    init() {
      swiper = new Swiper(this.$el, {
        modules: [Pagination, Mousewheel, EffectFade],
        effect: 'fade',
        direction: 'vertical',
        slidesPerView: 1,
        speed: 800,
        fadeEffect: {
          crossFade: false,
        },
        mousewheel: {
          forceToAxis: true, // Prevent horizontal scrolling
          sensitivity: 0.25, // Lower values make scrolling slower
          releaseOnEdges: true, // Smooth behavior when reaching start/end
        },
        loop: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          verticalClass: 'swiper-pagination-vertical',
        },
      });
    },
  };
});

Alpine.data('quotes', () => {
  let swiper = null;

  return {
    init() {
      swiper = new Swiper(this.$el, {
        modules: [Navigation, Autoplay],
        centeredSlides: true,
        loop: true,
        speed: 800,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        breakpoints: {
          768: {
            slidesPerView: 'auto',
          },
        },
      });
    },
  };
});

Alpine.data('featuredVideo', () => {
  let player = null;

  return {
    isPlaying: false,

    init() {
      player = new Player(this.$refs.video);
      player.on('play', () => {
        this.isPlaying = true;
      });
      player.on('pause', () => {
        this.isPlaying = false;
      });
    },

    play() {
      if (!player) return;
      player.play().catch((error) => {
        console.error('Error playing the video:', error);
      });
    },
    pause() {
      if (!player) return;
      player.pause().catch((error) => {
        console.error('Error pausing the video:', error);
      });
    },
  };
});

Alpine.data('videoLoader', () => ({
  loaded: false,

  init() {
    const player = new Player(this.$el);
    player.on('playing', () => {
      // Add a 0.5s delay so that the video fades in while the video has
      // properly started
      setTimeout(() => (this.loaded = true), 500);
    });
  },
}));

flatpickr.l10ns.default.weekdays.shorthand = [
  'S',
  'M',
  'T',
  'W',
  'T',
  'F',
  'S',
];

Alpine.data('datePicker', (defaultDate = 'today') => ({
  init() {
    flatpickr(this.$el, {
      nextArrow:
        '<svg width="0.5909090909em" height="1em" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.999999 21L11 11L1 1" stroke="currentColor" stroke-width="2"/></svg>',
      prevArrow:
        '<svg class="rotate-180" width="0.5909090909em" height="1em" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.999999 21L11 11L1 1" stroke="currentColor" stroke-width="2"/></svg>',
      defaultDate,
      minDate: 'today',
      dateFormat: 'F j, Y',
      allowInput: false,
      disableMobile: true,
      monthSelectorType: 'static',
    });
  },
}));

Alpine.data(
  'mapboxMap',
  ({
    accessToken,
    mapboxStyleUrl,
    zoom = 15,
    maxZoom = 18,
    minZoom = 10,
    center = [-87.623621, 41.888421],
  }) => {
    let map = null;

    return {
      init() {
        map = new mapboxgl.Map({
          accessToken,
          container: this.$el,
          style: mapboxStyleUrl,
          zoom,
          maxZoom,
          minZoom,
          center,
          attributionControl: false,
          dragRotate: false,
          scrollZoom: false,
          touchZoomRotate: false,
        });
        map.on('load', () => {
          map.resize();
        });
        map.dragRotate.disable();
        map.scrollZoom.disable();
        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        map.touchZoomRotate.disableRotation();
      },
    };
  },
);

Alpine.start();

// Silly hanging punctuation
document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('blockquote').forEach((blockquote) => {
    let text = blockquote.textContent.trim(); // Extract text without HTML tags

    // Check if text starts and ends with quotes
    const hasOpeningQuote = text.startsWith('“') || text.startsWith('"');
    const hasClosingQuote = text.endsWith('”') || text.endsWith('"');

    // Only add the class if both quotes exist
    if (hasOpeningQuote && hasClosingQuote) {
      blockquote.classList.add('hanging-quote');
    }
  });
});
